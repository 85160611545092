
































































import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {ITournamentEntity, TournamentState} from "@/core/tournaments/tournament.entity";
import {map} from 'lodash'
import {ITournamentParticipantEntity, ParticipantRole} from "@/core/tournaments/tournament-participant.entity";
import Pagination from "@/components/Shared/Pagination.vue";
import {Getter} from "vuex-class";
import {StoreNamespace} from "@/store/types";
import {IPagination} from "@/core/service/types";
import {CurrentTournamentModuleGetterTypes} from "@/store/modules/tournaments/currentTournament/types";
import {CurrentTournamentStoreModule, PopupsStoreModule} from "@/store";
import {RouteNames} from "@/router/types";
import {IGameAccountEntity} from "@/core/accounts/game-accounts/game-account.entity";
import {ITournamentRemoveParticipantPopupData} from "@/components/Tournaments/Popups/TournamentRemoveParticipantPopup.vue";
import {PopupType} from "@/core/popups/types";
import {ITournamentUpdateParticipantPopupData} from "@/components/Tournaments/Popups/TournamentUpdateParticipantPopup.vue";
import {ITournamentAcceptAddRequestPopupData} from "@/components/Tournaments/Popups/TournamentAcceptAddRequestPopup.vue";
import {AuthModuleGetterTypes} from "@/store/modules/auth/types";
import {IAuthUser} from "@/core/auth/types";

export interface ITournamentCardParticipantsTableData {
  index: number
  id: number
  place?: number
  gameAccount: IGameAccountEntity | null
  role: ParticipantRole
  status: boolean
}

@Component({
  components: {Pagination},
  enums: {
    RouteNames,
    ParticipantRole
  }
})
export default class TournamentCardParticipantsTable extends Vue {
  @Prop({type: Object, required: true, default: {}}) private readonly tournament!: ITournamentEntity

  @Getter(AuthModuleGetterTypes.USER, { namespace: StoreNamespace.Auth })
  private readonly user!: IAuthUser

  @Getter(CurrentTournamentModuleGetterTypes.TOURNAMENT_LEADERBOARD_PAGINATION, { namespace: StoreNamespace.CurrentTournament })
  private readonly pagination!: IPagination

  get headers () {
    if (this.tournament.state === TournamentState.PENDING) {
      return [
        { text: 'Player name', value: 'gameAccount' },
        { text: 'Role', value: 'role' },
        { text: 'Status', value: 'status' },
        { text: 'Actions', value: 'actions' },
      ]
    }

    return [
      { text: 'Place', value: 'place' },
      { text: 'Player name', value: 'gameAccount' },
      { text: 'Role', value: 'role' },
      { text: 'Status', value: 'status' },
      { text: 'Actions', value: 'actions' },
    ]
  }

  get participants (): ITournamentCardParticipantsTableData[] {
    return map(this.tournament.leaderboard, (participant: ITournamentParticipantEntity, index: number) => {
      return {
        index: index,
        id: participant.id,
        place: participant.place,
        gameAccount: participant.gameAccount,
        role: participant.role,
        status: participant.accepted,
        score: Number.isInteger(participant.score) ? participant.score : parseFloat(participant.score.toFixed(2)),
      } as ITournamentCardParticipantsTableData;
    }) as unknown as ITournamentCardParticipantsTableData[]
  }

  get currentUserIsOwner () {
    return this.tournament.owner_system_account_id === this.user.id
  }

  private onCurrentPageChange (newCurrentPage: number) {
    CurrentTournamentStoreModule.onCurrentPaginationPageChange(newCurrentPage)
  }

  private acceptTournamentParticipantInviteRequest (participantData: ITournamentCardParticipantsTableData) {
    PopupsStoreModule.showPopup({
      popupType: PopupType.TournamentAcceptAddRequest,
      data: {
        participant: participantData,
        tournament: this.tournament
      } as ITournamentAcceptAddRequestPopupData
    })
  }

  private updateParticipant (participantData: ITournamentCardParticipantsTableData) {
    PopupsStoreModule.showPopup({
      popupType: PopupType.TournamentUpdateParticipant,
      data: {
        participant: participantData,
        tournament: this.tournament
      } as ITournamentUpdateParticipantPopupData
    })
  }

  private removeParticipantFromTournament (participantData: ITournamentCardParticipantsTableData) {
    PopupsStoreModule.showPopup({
      popupType: PopupType.TournamentRemoveParticipant,
      data: {
        participant: participantData,
        tournament: this.tournament
      } as ITournamentRemoveParticipantPopupData
    })
  }
}
