
















import Vue from 'vue'
import {Component, Prop} from "vue-property-decorator";
import {ITournamentEntity} from "@/core/tournaments/tournament.entity";
import TournamentAccountAvatar from "@/components/Tournaments/TournamentAccountAvatar.vue";
import TournamentSponsorAvatar from "@/components/Tournaments/TournamentSponsorAvatar.vue";
import {RouteNames} from "@/router/types";
import TournamentCardHeader from "@/components/Tournaments/TournamentCard/TournamentCardHeader.vue";
import TournamentCardContainer from "@/components/Tournaments/TournamentCard/TournamentCardContainer.vue";
import ResponsiveContainer from "@/components/Shared/ResponsiveContainer.vue";

@Component({
  components: {
    ResponsiveContainer,
    TournamentCardContainer, TournamentCardHeader, TournamentSponsorAvatar, TournamentAccountAvatar},
  enums: {RouteNames}
})
export default class TournamentCard extends Vue {
  @Prop({ type: Object, required: true, default: {} }) private readonly tournament!: ITournamentEntity

  get avatarSize (): number {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return 50
      case "sm":
        return 66
      default:
        return 100
    }
  }
}
