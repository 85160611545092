









































import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {ITournamentEntity, TournamentState} from "@/core/tournaments/tournament.entity";
import {map} from 'lodash'
import {ITournamentParticipantEntity} from "@/core/tournaments/tournament-participant.entity";
import Pagination from "@/components/Shared/Pagination.vue";
import {Getter} from "vuex-class";
import {StoreNamespace} from "@/store/types";
import {IPagination} from "@/core/service/types";
import {CurrentTournamentModuleGetterTypes} from "@/store/modules/tournaments/currentTournament/types";
import {CurrentTournamentStoreModule} from "@/store";
import {RouteNames} from "@/router/types";
import {IGameAccountEntity} from "@/core/accounts/game-accounts/game-account.entity";

interface ITournamentCardLeaderboardData {
  place: number
  gameAccount: IGameAccountEntity | null
  status: boolean
  matchCounter: number
  score: number
  defeatsCounter: number
  winsCounter: number
}

@Component({
  components: {Pagination},
  enums: {
    RouteNames
  }
})
export default class TournamentCardLeaderboardTable extends Vue {
  @Prop({type: Object, required: true, default: {}}) private readonly tournament!: ITournamentEntity

  @Getter(CurrentTournamentModuleGetterTypes.TOURNAMENT_LEADERBOARD_PAGINATION, { namespace: StoreNamespace.CurrentTournament })
  private readonly pagination!: IPagination

  get headers () {
    if (this.tournament.state === TournamentState.PENDING) {
      return [
        { text: 'Player name', value: 'gameAccount' },
        { text: 'Status', value: 'status' },
        { text: 'Played', value: 'matchCounter' },
        { text: 'Wins', value: 'winsCounter' },
        { text: 'Defeats', value: 'defeatsCounter' },
        { text: 'Score', value: 'score' },
      ]
    }

    return [
      { text: 'Place', value: 'place' },
      { text: 'Player name', value: 'gameAccount' },
      { text: 'Status', value: 'status' },
      { text: 'Played', value: 'matchCounter' },
      { text: 'Wins', value: 'winsCounter' },
      { text: 'Defeats', value: 'defeatsCounter' },
      { text: 'Score', value: 'score' },
    ]
  }

  get participants (): ITournamentCardLeaderboardData[] {
    return map(this.tournament.leaderboard, (participant: ITournamentParticipantEntity) => {
      return {
        place: participant.place,
        gameAccount: participant.gameAccount,
        matchCounter: participant.match_counter,
        winsCounter: participant.wins_counter,
        defeatsCounter: participant.defeats_counter,
        status: participant.accepted,
        score: Number.isInteger(participant.score) ? participant.score : parseFloat(participant.score.toFixed(2)),
      } as ITournamentCardLeaderboardData;
    }) as unknown as ITournamentCardLeaderboardData[]
  }

  private onCurrentPageChange (newCurrentPage: number) {
    CurrentTournamentStoreModule.onCurrentPaginationPageChange(newCurrentPage)
  }

  private getPlaceColor (place: number) {
    switch (place) {
      case 1:
        return this.$vuetify.theme.currentTheme.success!['base']
      case 2:
        return this.$vuetify.theme.currentTheme.info
      case 3:
        return this.$vuetify.theme.currentTheme.warning
      default:
        return this.$vuetify.theme.currentTheme.secondary
    }
  }
}
