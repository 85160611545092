


















import Vue from 'vue'
import {Component, Watch} from "vue-property-decorator";
import {CurrentTournamentStoreModule} from "@/store";
import {Getter} from "vuex-class";
import {StoreNamespace} from "@/store/types";
import CircularLoading from "@/components/Loading/CircularLoading.vue";
import {VuexState} from "@/core/vuex/types";
import TournamentCard from "@/components/Tournaments/TournamentCard/TournamentCard.vue";
import {ITournamentEntity} from "@/core/tournaments/tournament.entity";
import {CurrentTournamentModuleGetterTypes} from "@/store/modules/tournaments/currentTournament/types";
import ResponsiveContainer from "@/components/Shared/ResponsiveContainer.vue";

@Component({
  components: {ResponsiveContainer, TournamentCard, CircularLoading},
  enums: {VuexState}
})
export default class TournamentPage extends Vue {
  @Getter(CurrentTournamentModuleGetterTypes.TOURNAMENT_VUEX_STATE, { namespace: StoreNamespace.CurrentTournament })
  private readonly currentState!: VuexState

  @Getter(CurrentTournamentModuleGetterTypes.TOURNAMENT, { namespace: StoreNamespace.CurrentTournament })
  private readonly tournament!: ITournamentEntity

  private created () {
    CurrentTournamentStoreModule.initialize(this.$route.params.tournamentId)
  }

  @Watch('$route.params')
  private onRouteParamsChanged (newRouteParams: any) {
    CurrentTournamentStoreModule.initialize(newRouteParams.tournamentId)
  }

  private beforeDestroy () {
    CurrentTournamentStoreModule.clearStore()
  }
}
