







import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {ITournamentEntity} from "@/core/tournaments/tournament.entity";
import {PopupsStoreModule} from "@/store";
import {PopupType} from "@/core/popups/types";
import {Getter} from "vuex-class";
import {AccountGameAccountsModuleGetterTypes} from "@/store/modules/account/gameAccounts/types";
import {StoreNamespace} from "@/store/types";
import {IGameAccountEntity} from "@/core/accounts/game-accounts/game-account.entity";
import {ITournamentRemoveParticipantPopupData} from "@/components/Tournaments/Popups/TournamentCancelPopup.vue";

@Component({})
export default class TournamentCancelButton extends Vue {
  @Prop({type: Object, required: true, default: {}}) private readonly tournament!: ITournamentEntity

  @Getter(AccountGameAccountsModuleGetterTypes.GAME_ACCOUNTS, { namespace: StoreNamespace.AccountGameAccounts })
  private readonly gameAccounts!: IGameAccountEntity[]

  private onTournamentCancelAction () {
    PopupsStoreModule.showPopup({
      popupType: PopupType.TournamentCancel,
      data: {
        tournament: this.tournament,
      } as ITournamentRemoveParticipantPopupData
    })
  }
}
