


















import {Component, Prop} from "vue-property-decorator";
import TournamentSponsorAvatar from "../TournamentSponsorAvatar.vue";
import TournamentAccountAvatar from "../TournamentAccountAvatar.vue";
import {RouteNames} from "@/router/types";
import Vue from "vue";
import {ITournamentEntity, TournamentState} from "@/core/tournaments/tournament.entity";
import TournamentInfoContainer from "@/components/Tournaments/TournamentInfo/TournamentInfoContainer.vue";
import TournamentCardLeaderboardTable from "@/components/Tournaments/TournamentCard/TournamentCardLeaderboardTable.vue";
import TournamentTopScorePlayer from "@/components/Tournaments/TournamentCard/TournamentTopScorePlayer.vue";
import TournamentRulesCard from "@/components/Tournaments/TournamentCard/TournamentRulesCard.vue";
import TournamentTargetCard from "@/components/Tournaments/TournamentCard/TournamentTargetCard.vue";
import {Getter} from "vuex-class";
import {AuthModuleGetterTypes} from "@/store/modules/auth/types";
import {StoreNamespace} from "@/store/types";
import {IAuthUser} from "@/core/auth/types";
import {ParticipantRole} from "@/core/tournaments/tournament-participant.entity";
import TournamentCardParticipantsTable
  from "@/components/Tournaments/TournamentCard/TournamentCardParticipantsTable.vue";

@Component({
  components: {
    TournamentCardParticipantsTable,
    TournamentTargetCard,
    TournamentRulesCard,
    TournamentTopScorePlayer,
    TournamentCardLeaderboardTable, TournamentInfoContainer, TournamentSponsorAvatar, TournamentAccountAvatar},
  enums: {RouteNames}
})
export default class TournamentCardContainer extends Vue {
  @Prop({type: Object, required: true, default: {}}) private readonly tournament!: ITournamentEntity

  @Getter(AuthModuleGetterTypes.USER, { namespace: StoreNamespace.Auth })
  private readonly user!: IAuthUser

  get isCurrentUserCanEditTournament () {
    if (this.tournament.owner_system_account_id === this.user.id) {
      return this.tournament.state === TournamentState.PENDING
    }

    if (this.tournament.my_participation) {
      if (this.tournament.my_participation.role === ParticipantRole.OWNER || this.tournament.my_participation.role === ParticipantRole.MODERATOR) {
        return this.tournament.state === TournamentState.PENDING
      }
    }

    return false
  }
}
