var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-toolbar-title',{staticClass:"mb-3"},[_vm._v("Leaderboard")]),_c('v-card',{attrs:{"outlined":"","flat":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.participants,"disable-filtering":"","hide-default-footer":"","page":_vm.pagination.current_page},scopedSlots:_vm._u([{key:"item.place",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getPlaceColor(item.place),"small":""}},[_vm._v(" "+_vm._s(item.place)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === true)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success"}},'v-icon',attrs,false),on),[_vm._v("mdi-check-decagram")])]}}],null,true)},[_c('span',[_vm._v("Accepted")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-decagram")])]}}],null,true)},[_c('span',[_vm._v("Not Accepted")])])]}},{key:"item.gameAccount",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{ name: _vm.RouteNames.LolWelcome, params: { name: item.gameAccount.name, region: item.gameAccount.region_code } }}},[_vm._v(" "+_vm._s(item.gameAccount.name)+" ")])]}}])}),_c('Pagination',{staticClass:"pt-2 pb-2",attrs:{"pagination":_vm.pagination},on:{"on-current-page-change":_vm.onCurrentPageChange}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }