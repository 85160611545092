










import {Component, Prop} from "vue-property-decorator";
import {RouteNames} from "@/router/types";
import Vue from "vue";
import {ITournamentEntity} from "@/core/tournaments/tournament.entity";

@Component({
  enums: {RouteNames}
})
export default class TournamentRulesContainer extends Vue {
  @Prop({type: Object, required: true, default: {}}) private readonly tournament!: ITournamentEntity
}
