
















import {Component, Prop} from "vue-property-decorator";
import {RouteNames} from "@/router/types";
import Vue from "vue";
import {ITournamentEntity} from "@/core/tournaments/tournament.entity";
import {groupBy} from 'lodash'

@Component({
  enums: {RouteNames}
})
export default class TournamentRulesCard extends Vue {
  @Prop({type: Object, required: true, default: {}}) private readonly tournament!: ITournamentEntity

  get groupedConstraints () {
    return groupBy(this.tournament.constraints, (constraint) => constraint.constraint!.type)
  }
}
