










import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {ITournamentEntity} from "@/core/tournaments/tournament.entity";
import {ITournamentParticipantEntity} from "@/core/tournaments/tournament-participant.entity";
import TournamentAccountAvatar from "@/components/Tournaments/TournamentAccountAvatar.vue";

@Component({
  components: {TournamentAccountAvatar}
})
export default class TournamentTopScorePlayer extends Vue {
  @Prop({type: Object, required: true, default: {}}) private readonly tournament!: ITournamentEntity

  get isTopScorePlayerExist (): boolean {
    return this.tournament.top_score !== null
  }

  get topScorePlayer (): ITournamentParticipantEntity | null {
    if (!this.isTopScorePlayerExist) {
      return null
    }

    return this.tournament.top_score as unknown as ITournamentParticipantEntity
  }
}
